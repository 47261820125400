.splide__pagination {
    counter-reset: pagination-num;
    bottom: -10%;
    left: 0;
    padding: 0 1em;
    position: absolute;
    right: 0;
    z-index: 1;
  }
  
  .splide__pagination__page:before {
    counter-increment: pagination-num;
    content: counter( pagination-num );
  }



  .splide__pagination__page {
    padding-top: 60px;
    background: #82918E;
    border: 0;
    border-radius: 50%;
    display: inline-block;
    height: 23px;
    margin: 3px;
    opacity: .7;
    padding: 0;
    position: relative;
    transition: transform .2s linear;
    width: 23px;
    color: black
  }

  .splide__pagination__page.is-active {
    background: #0C9347;
    transform: scale(1.4);
    z-index: 1;
    color: white;
  }
 /* body {
     font-family: 'Fira Sans', sans-serif;
 } */

 body {
    font-family: Fira Sans, 'Roboto', 'Fira Code' , "Bodoni Moda";
 }

 a {
text-decoration: none;
 }

 html {
    background-color:  #FBF7EC;
 }